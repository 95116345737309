<template>
  <div class='JZContainer'>
    <content-block >
      <query-form  size="small" ref='JZForm'  label-width="120px" @search="handleSearch" @reset='handleReset'>
        <el-form-item label="销售单号：">
            <el-input v-model.trim="JZForm.orderCode"  placeholder="请输入销售单号"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：">
            <el-input v-model.trim="JZForm.goodsName"  placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="规格：">
            <el-input v-model.trim="JZForm.specification"  placeholder="请输入规格"></el-input>
        </el-form-item>
        <el-form-item label="型号：">
            <el-input v-model.trim="JZForm.model"  placeholder="请输入型号"></el-input>
        </el-form-item>
        <el-form-item label="品牌名称">
            <el-input v-model.trim="JZForm.brandName"  placeholder="请输入品牌名称"></el-input>
        </el-form-item>
        <el-form-item label="厂家编码：">
            <el-input v-model.trim="JZForm.factoryCode"  placeholder="请输入厂家编码"></el-input>
        </el-form-item>
        <el-form-item label="客户抬头：">
            <el-input v-model.trim="JZForm.unit"  placeholder="请输入客户抬头"></el-input>
        </el-form-item>
        <el-form-item label="销售合同号：">
            <el-input v-model.trim="JZForm.pcCode"  placeholder="请输入销售合同号"></el-input>
        </el-form-item>
        <el-form-item label="出库日期(起)：" prop="operationTimeBegin" >
          <span slot="label" title="出库日期(起)">
            出库日期(起)：
          </span>
          <el-date-picker @change='handleSearch' type="date"  placeholder="请选择出库日期(起)" value-format="yyyy-MM-dd"  v-model.trim="JZForm.operationTimeBegin"></el-date-picker>
        </el-form-item>
        <el-form-item label="出库日期(止)：" prop="operationTimeEnd" >
            <span slot="label" title="出库日期(止)">
            出库日期(止)：
          </span>
          <el-date-picker @change='handleSearch' type="date"  placeholder="请选择出库日期(止)" value-format="yyyy-MM-dd"  v-model.trim="JZForm.operationTimeEnd"></el-date-picker>
        </el-form-item>
      </query-form>
    </content-block>
    <content-block  >
      <el-button size='mini' @click.native="exportTable" type="primary" style='margin-bottom:10px;'>导出</el-button>
      <TableWithPagination
        :tableData="targetLists"
        ref="orderTable"
        :columnList="columnList"
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
      ></TableWithPagination>
    </content-block>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import { exportRecord, getRecordLists} from '@/api/userCenter';
import * as Moment from 'dayjs';
export default {
  name: 'Product',
  mixins: [InfoMixin],
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);
  },
  methods: {
    getTargetLists () {
      let currentParams = this.getCurrentParams();
      getRecordLists(currentParams).then(res => {
        let targetLists = res.body.list || [];
        this.targetLists = targetLists.map(item=> {
          return {
            ...item,
            occurTime: Moment(item.occurTime).format('YYYY-MM-DD'),
          };
        });
        this.pagination.total = res.body.total;
      });
    },
    exportTable () {
      let currentParams = this.getCurrentParams();
      exportRecord(currentParams).then(({data}) => {
        const downloadData = {
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `销售发货记录_${Date.now()}`,
        };
        this.downBinaryStreamHandle(downloadData);
      });
    },
    getCurrentParams () {
      let currentParams = {
        ...this.JZForm,
        type: 1,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      currentParams.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      }];
      return currentParams;
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    handleReset () {
      this.JZForm = {
        orderCode: '',
        goodsName: '',
        specification: '',
        model: '',
        factoryCode: '',
        brandName: '',
        operationTimeBegin: '',
        operationTimeEnd: '',
        pcCode: '',
        unit: '',
      };
      this.handleSearch();
    },
  },
  data () {
    return {
      JZForm: {
        orderCode: '',
        goodsName: '',
        specification: '',
        model: '',
        factoryCode: '',
        brandName: '',
        operationTimeBegin: '',
        operationTimeEnd: '',
        pcCode: '',
        unit: '',
      },
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '客户抬头',
          minWidth: '180px',
          prop: 'unit',
        },
        {
          tooltip: true,
          label: '销售单号',
          minWidth: '150px',
          prop: 'orderCode',
        },
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '150px',
          prop: 'goodsName',
        },
        {
          tooltip: true,
          label: '规格',
          minWidth: '120px',
          prop: 'specification',
        },
        {
          tooltip: true,
          label: '型号',
          minWidth: '120px',
          prop: 'model',
        },
        {
          tooltip: true,
          label: '品牌名称',
          minWidth: '200px',
          prop: 'brandName',
        },
        {
          tooltip: true,
          label: '生产厂家',
          minWidth: '200px',
          prop: 'producer',
        },
        {
          tooltip: true,
          label: '厂家编码',
          minWidth: '130px',
          prop: 'factoryCode',
        },
        {
          tooltip: true,
          label: '是否医疗件',
          minWidth: '110px',
          prop: 'isMedichair',
          format: (val) => {
            return val === 1 ? '是' : val === 2 ? '否' : '';
          },
        },
        {
          tooltip: true,
          label: '计量单位',
          minWidth: '110px',
          prop: 'unitOfMsment',
        },
        {
          tooltip: true,
          label: '出库数量',
          minWidth: '110px',
          prop: 'num',
        },
        {
          tooltip: true,
          label: '销售单价',
          minWidth: '110px',
          prop: 'unitPrice',
        },
        {
          tooltip: true,
          label: '出库单号',
          minWidth: '180px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '出库日期',
          minWidth: '110px',
          prop: 'occurTime',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .el-date-editor.el-input, /deep/ .el-date-editor.el-input__inner{
  width:100%;
}
</style>
